/* eslint-disable */
import React from 'react';
import Link from 'next/link';
import DepositBanner from './deposit-banner';
import { useTranslation } from '@/app/i18n/client';
import { Button, Heading, Text } from '@purposeinplay/core-v2';
import ContentAreaWrapper from '@/components/ContentAreaWrapper';
import { HomepageContentType } from '@/types/json-content/homepage';
import Picture from './hero-image';
import Reviews from './reviews';
import { useAuth } from '@/hooks/auth';

const HeroBanner = () => {
  const { t } = useTranslation();
  const content = t('homepage') as HomepageContentType;
  const { user } = useAuth();
  return (
    <div className="mx-auto max-w-7xl  2xl:max-w-8xl 2xl:px-0">
      <div className="relative">
        <div className="md:-mt-6">
          <div className="relative -mx-3 mb-2  min-h-[23rem] lg:-mx-10 lg:min-h-[28rem]">
            <Picture />
          </div>
          <div className="relative inset-0 z-10 flex items-center justify-center text-center sm:absolute sm:-mt-0 sm:justify-start md:text-left xl:max-w-9xl">
            <ContentAreaWrapper className="relative mx-0 px-0 pt-0 md:mb-0 lg:max-w-[40rem] lg:px-0">
              <div className="relative mb-spacing-md px-0.5 text-lg leading-none sm:mx-0 sm:mb-2 sm:text-left md:mb-7">
                <Text
                  as="span"
                  size="2xl"
                  className="inline-block text-sm font-medium md:mb-1 md:text-3xl"
                >
                  {content?.guest_banner?.top_title_v2}
                </Text>

                <Heading
                  as="h2"
                  className="max-w-[25rem] px-1 text-3xl font-extrabold leading-none text-yellow-400 sm:mb-1 sm:max-w-[33.75rem] sm:px-0 sm:text-5xl lg:max-w-none  2xl:max-w-[40rem]"
                >
                  {content?.guest_banner?.heading_v2}
                </Heading>
                <Text
                  as="span"
                  size="2xl"
                  className="inline-block text-sm font-bold md:text-2xl"
                >
                  {content?.guest_banner?.description_v2}
                </Text>
              </div>
              <Link
                shallow
                href="/?m=signup"
                className="focus-visible::ring-transparent  focus-visible:!outline-none"
              >
                <Button
                  linkHref="/?m=signup"
                  size="lg"
                  className="mb-7 h-auto rounded-xl"
                >
                  <div className="flex flex-col items-center text-center">
                    <span className="text-lg">
                      {content?.guest_banner?.action?.btn_label.primary}
                    </span>
                    <span className="text-xs italic">
                      {content?.guest_banner?.action?.btn_label.secondary}
                    </span>
                  </div>
                </Button>
              </Link>
              <Reviews />
            </ContentAreaWrapper>
          </div>
        </div>
      </div>
      <DepositBanner />
    </div>
  );
};

export default HeroBanner;
