import { useTranslation } from '@/app/i18n/client';
import { useAnalytics } from '@/hooks/use-analytics';
import { HomepageContentType } from '@/types/json-content/homepage';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { cn } from '@/utils/style';
import { Badge, Heading, Text } from '@purposeinplay/core-v2';
import Image from 'next/image';
import Link from 'next/link';
import React, { useCallback } from 'react';

// Reusable Banner Component
interface BannerProps {
  href: string;
  imageSrc: string;
  title: string;
  badgeText: string;
  description: string;
  badgeType: 'error' | 'purple';
  shadowColor: string;
  iconName?: string;
  onClick: () => void;
}

const Banner: React.FC<BannerProps> = ({
  href,
  imageSrc,
  title,
  badgeText,
  description,
  badgeType,
  shadowColor,
  iconName,
  onClick,
  ...props
}) => {
  return (
    <Link
      {...props}
      href={href}
      onClick={onClick}
      prefetch={false}
      className={cn([
        `after-shadow relative flex items-end overflow-hidden rounded-2xl bg-surface-default p-4 transition-all duration-300 after:h-full after:rounded-b-2xl hover:-translate-y-1  md:p-7 after:md:h-32 lg:h-80`,
        shadowColor,
      ])}
    >
      <Image
        src={imageSrc}
        fill
        alt={`${title} Banner`}
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
        className="hidden rounded-2xl object-cover object-top lg:block"
      />
      <div className="relative z-10 max-w-84">
        <div className="mb-1 flex flex-row items-center space-x-2">
          <div className="flex flex-row items-center space-x-1 lg:space-x-0">
            <Image
              loader={DEFAULT_IMAGE_LOADER}
              src={`assets/${iconName}.svg`}
              width={22}
              height={22}
              alt="Offline Image"
              className="lg:hidden"
            />
            <Heading
              as="h2"
              className="text-lg font-bold text-text-on-primary md:text-2xl"
            >
              {title}
            </Heading>
          </div>

          <Badge radius="rounded" type={badgeType} size="md">
            {badgeText}
          </Badge>
        </div>
        <Text size="sm" className="text-text-subdued">
          {description}
        </Text>
      </div>
    </Link>
  );
};

// HomeBanners Component
const HomeBanners = () => {
  const { t } = useTranslation();
  const homepage = t('homepage') as HomepageContentType;
  const content = homepage?.banners;
  const { track } = useAnalytics();

  const handleBannerClick = useCallback(
    (name: string) => {
      track('banner_clicked', {
        category: 'banner',
        label: name,
        action: 'click',
      });
    },
    [track],
  );

  return (
    <div className="grid gap-spacing-xs sm:grid-cols-2 md:gap-7">
      <Banner
        onClick={() => handleBannerClick('casino')}
        data-theme="dark"
        iconName="seven"
        href={content?.casino?.href || '/'}
        imageSrc="/assets/banner_casino.webp"
        title={content?.casino?.title || ''}
        badgeText={content?.casino?.badge_txt || ''}
        description={content?.casino?.description || ''}
        badgeType="error"
        shadowColor={cn([
          'after:bg-gradient-to-b after:from-[#4200B3] after:to-[#1B236D] after:lg:bg-gradient-to-t after:lg:from-indigo-950 after:lg:to-transparent',
        ])}
      />
      <Banner
        onClick={() => handleBannerClick('sports')}
        data-theme="dark"
        iconName="football"
        href={content?.sports?.href || '/'}
        imageSrc="/assets/banner_sports.webp"
        title={content?.sports?.title || ''}
        badgeText={content?.sports?.badge_txt || ''}
        description={content?.sports?.description || ''}
        badgeType="purple"
        shadowColor={cn([
          'after:bg-gradient-to-b after:to-[#07494E] after:from-[#036E28] after:lg:bg-gradient-to-t after:lg:from-green-900 after:lg:to-transparent',
        ])}
      />
    </div>
  );
};

export default HomeBanners;
