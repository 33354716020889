import TPFullStarSVG from 'public/assets/stars/full-star.svg';
import TPHalfStarSVG from 'public/assets/stars/half-star.svg';
import React from 'react';

const Reviews = () => {
  return (
    <div className="flex flex-wrap items-center justify-center gap-3 sm:justify-start">
      <span className="text-xs font-bold">Great</span>
      <div className="flex space-x-1">
        <TPFullStarSVG className="h-4 w-4" />
        <TPFullStarSVG className="h-4 w-4" />
        <TPFullStarSVG className="h-4 w-4" />
        <TPFullStarSVG className="h-4 w-4" />
        <TPHalfStarSVG className="h-4 w-4" />
      </div>
      <div className="flex items-center ">
        <span className="text-xs font-bold text-text-default">550+ </span>
        <span className="text-xs text-text-default">&nbsp;User Reviews</span>
      </div>
    </div>
  );
};

export default Reviews;
