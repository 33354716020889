'use client';
import React, { Suspense, useMemo } from 'react';
import ContentAreaWrapper from '../ContentAreaWrapper';
import Disclosure from '../Disclosure';
import { useAuth } from '@/hooks/auth';
import { useTranslation } from '@/app/i18n/client';
import { HomepageContentType } from '@/types/json-content/homepage';
import Section from './components/section';
import RakebackBanner from '../RakebackBanner';
import ProvidersSVG from 'public/assets/home-category-icons/providers.svg';
import HomeProviders from './components/providers';
import Image from 'next/image';
import GamesCarousel from '../Carousels/games-carousel';
import { useProviders } from '@/hooks/providers';
import Sports from './components/sports';
import { useWildCategories } from '@/hooks/collections';
import BigWins from './components/BigWins';
import Leaderboard from '../WinnersLeaderboard/leaderboard';
import CryptoList from '../CryptoList';
import BadgeLink from '../BadgeLink';
import { Page } from '@/generated';
import { ComponentDisclosureType } from '@/types/others';
import Hero from '@/modules/home/components/hero-component';
import { cn } from '@/utils/style';
import HomeBanners from './components/banners';
import { usePathname } from 'next/navigation';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';

const Home = React.memo(({ page }: { page: Page }) => {
  const pathname = usePathname();
  const { t } = useTranslation();
  const content = t('homepage') as HomepageContentType;
  const { data: providers = [] } = useProviders();

  const { user } = useAuth();
  const { data: collections } = useWildCategories();

  const gamesCount = useMemo(() => {
    if (!collections) return;
    return collections?.reduce(function (result: any, item: any) {
      result[item?.id] = item?.games_count;
      return result;
    }, {} as any);
  }, [collections]);

  const foundDisclosure = page?.content?.find(
    (content) => content?.__typename === 'ComponentContentDisclosure',
  ) as unknown as ComponentDisclosureType;

  const shouldDisplayHero = pathname === '/' && !user?.isAuthenticated;

  return (
    <div>
      <ContentAreaWrapper>
        {shouldDisplayHero && (
          <Section className={cn(['relative z-0'])}>
            <Hero />
          </Section>
        )}
        <Section>
          <HomeBanners />
        </Section>

        <Section
          title={content?.big_wins?.label}
          titleStyles="mb-3 [&>div>h2]:text-[16px]"
          icon={
            <div className="scale-animation relative ml-2 h-2 w-2 rounded-full bg-[#47BB78]" />
          }
        >
          <BigWins />
        </Section>
        <Section
          titleStyles="mb-0"
          title={content?.casino?.label}
          href="/games"
          trailingElement={
            <BadgeLink
              text={content?.casino?.action?.btn_label}
              gamesCount={gamesCount && gamesCount['all']}
              href="/games"
            />
          }
          icon={
            <Image
              src="/assets/providers-page.svg"
              fill
              alt={`Casino icon`}
              loader={DEFAULT_IMAGE_LOADER}
            />
          }
        >
          <GamesCarousel
            tagType="primary"
            tagText="new"
            identifier="fresh-releases"
          />
        </Section>
        <Section>
          <RakebackBanner />
        </Section>
        <Section
          title={content?.sports?.label}
          href={content?.sports?.action?.href}
          icon={
            <Image
              src="/assets/sports-category.svg"
              fill
              alt={`Sports icon`}
              loader={DEFAULT_IMAGE_LOADER}
            />
          }
          trailingElement={
            <BadgeLink
              text={content?.sports?.action?.btn_label}
              gamesCount={content?.sports?.items?.length}
              href={content?.sports?.action?.href}
            />
          }
        >
          <Sports />
        </Section>
        <Section
          icon={<ProvidersSVG />}
          title={content?.game_providers?.label}
          href={content?.game_providers?.action?.href}
          trailingElement={
            <BadgeLink
              text={content?.game_providers?.action?.btn_label}
              gamesCount={providers?.length}
              href={content?.game_providers?.action?.href}
            />
          }
        >
          <HomeProviders providers={providers} />
        </Section>

        <Section className="mb-spacing-0 md:mb-spacing-0">
          <Leaderboard />
        </Section>

        <div className="flex flex-col border-surface-depressed lg:flex-col-reverse xl:border-b">
          {foundDisclosure?.disclosureContent && (
            <Disclosure content={foundDisclosure?.disclosureContent} />
          )}
        </div>
        <CryptoList />
      </ContentAreaWrapper>
    </div>
  );
});

Home.displayName = 'HomeLanding';

export default Home;
