import React, { useCallback, useMemo } from 'react';
import { BigWinsCarouselLoader } from '@/components/Loaders';
import WinItemCard from './win-item-card';
import {
  useLiveWinners,
  useLiveWinnersReversed,
} from '@/hooks/use-live-winners';
import { usePreloadedImages } from '@/hooks/use-preload-images';
import { LiveWinner, NewWinner } from '@/types/winners';
import { FALLBACK_SRC_BASE } from '@/components/GameCard/constants';

const getImageUrl = (winner: LiveWinner | NewWinner) =>
  `https://cdn.wild.io/thumbnail/${winner?.game?.identifier}.png`;

const getFallbackUrl = (winner: LiveWinner | NewWinner) =>
  `${FALLBACK_SRC_BASE}/thumbnail/${winner?.game?.identifier.split('/')[0]}/${winner?.game?.identifier.split('/')[1]}.webp`;

const BigWins = () => {
  const { winners, isLoading } = useLiveWinnersReversed();

  const images = usePreloadedImages(winners, getImageUrl, getFallbackUrl);

  const slides = useMemo(() => {
    if (isLoading) return;
    const usedKeys: { [key: string]: boolean } = {};

    const uniqueWinners = winners.filter((winner: any) => {
      const key = `${winner?.round?.bet ?? winner?.bet_amount_cents}-${winner?.round?.win ?? winner?.win_amount_cents}`;
      if (usedKeys[key]) {
        return false;
      }
      usedKeys[key] = true;
      return true;
    });

    return uniqueWinners?.map((winner: any, index) => {
      const foundImage = images?.find(
        (item) => item.identifier === winner?.game?.identifier,
      );

      return (
        <div
          key={`${winner?.round?.bet ?? winner?.bet_amount_cents}-${winner?.round?.win ?? winner?.win_amount_cents}-${index}`}
        >
          <WinItemCard
            index={index}
            game={winner?.game}
            amount={winner?.round?.win ?? winner?.win_amount_cents}
            currency={winner?.round?.currency ?? winner?.currency}
            imageUrl={foundImage?.imageUrl ?? ''}
          />
        </div>
      );
    });
  }, [images, isLoading, winners]);

  return isLoading ? (
    <BigWinsCarouselLoader />
  ) : (
    <div className="relative overflow-hidden">
      <div className="flex -translate-x-[120px] flex-row space-x-2 2xl:-translate-x-[134px] 2xl:space-x-2.5">
        {slides}
      </div>
    </div>
  );
};

export default BigWins;
