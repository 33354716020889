import React, { Suspense } from 'react';
import { Text, Button } from '@purposeinplay/core-v2';
import { useAuth } from '@/hooks/auth';
import useModal from '@/hooks/modal';
import Image from 'next/image';
import { useTranslation } from '@/app/i18n/client';
import { HomepageContentType } from '@/types/json-content/homepage';
import '../styles/banner.css';
import { ButtonLoader } from '@/components/Loaders';
import { CURRENCY_ORDER } from '@/utils/constants';
import useMediaQuery, {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  XL_BREAKPOINT,
} from '@/hooks/use-media-query';

const DepositBannerAction = () => {
  const { t } = useTranslation();
  const homepageContent = t('homepage') as HomepageContentType;
  const content = homepageContent?.crypto_banner;
  const { user } = useAuth();
  const { open } = useModal();
  return (
    <Button
      onClick={() =>
        user?.isAuthenticated
          ? open('wallet', { a: 'deposit' })
          : open('signup')
      }
      size="md"
      className="min-w-max"
    >
      {content?.action?.btn_label}
    </Button>
  );
};

const DepositBanner = () => {
  const { t } = useTranslation();
  const homepageContent = t('homepage') as HomepageContentType;
  const content = homepageContent?.crypto_banner;
  const { matchesQuery: isXL } = useMediaQuery(XL_BREAKPOINT);
  return (
    <div
      className={`custom-gradient relative z-50 mt-7 flex w-full
      overflow-hidden rounded-2xl sm:mt-0`}
    >
      <div className="relative z-10  flex w-full flex-col space-y-spacing-md px-4 py-3  md:flex-row md:items-center md:justify-between md:space-x-2 md:space-y-0 ">
        <Text
          size="xl"
          className="text-sm sm:truncate md:text-xl md:font-medium"
        >
          {content?.heading}
        </Text>
        <div className="inline-flex items-center justify-start  space-x-1 truncate  md:space-x-2">
          {CURRENCY_ORDER.map((currency) => (
            <div className="shrink-0" key={`currency-icon-${currency}`}>
              <Image
                src={`assets/accounts/${currency}.svg`}
                width={isXL ? 32 : 22}
                height={isXL ? 32 : 22}
                alt={`${currency} Icon`}
              />
            </div>
          ))}
        </div>

        <Suspense
          fallback={
            <ButtonLoader className="h-10 w-[84px] bg-surface-subdued dark:bg-surface-subdued" />
          }
        >
          <DepositBannerAction />
        </Suspense>
      </div>
      <div className="pointer-events-none absolute bottom-0 top-0 w-1/5  translate-x-[30rem]">
        <Image
          className={`pointer-events-none z-0 object-cover object-center !p-0 opacity-10 dark:opacity-100`}
          src={`assets/deposit-banner-bg.svg`}
          priority
          alt="Deposit overlay"
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
          fill
        />
      </div>
    </div>
  );
};

export default DepositBanner;
