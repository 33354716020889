import AmountDisplay from '@/components/AmountDisplay';
import useShouldFillProfile from '@/hooks/use-should-fill-profile';
import { Currency } from '@/types/currency';
import { Game } from '@/types/games';
import { FilteredGame } from '@/types/games_filters';
import { Badge, Text } from '@purposeinplay/core-v2';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import './styles/big-wins.css';
// @ts-ignore
import getSlug from 'speakingurl';
import { cn } from '@/utils/style';
import { BigWinsLoader } from '@/components/Loaders';

type Props = {
  game: FilteredGame;
  imageUrl: string;
  amount: number;
  currency: Currency['code'];
  index?: number;
};

const WinItemCard: React.FC<Props> = React.memo(
  ({ game, imageUrl, amount, currency, index }) => {
    const shouldFillProfile = useShouldFillProfile(game?.identifier);
    const slug = game?.seo_title;

    if (!imageUrl) {
      return <BigWinsLoader />;
    }

    return (
      <Link
        className={cn([
          'flex w-28 flex-col rounded-radius-md bg-bgr-lighter p-padding-md duration-300 hover:-translate-y-2 2xl:w-32',
          `animated-row-horizontal ${index === 0 ? 'first-child' : ''}`,
        ])}
        href={`/games/${slug}`}
        passHref
        prefetch={false}
        onClick={(e) => {
          if (shouldFillProfile) {
            e.preventDefault();
            open('fillProfile');
            return;
          }
        }}
      >
        <div className="relative mb-2 h-32 w-full rounded-radius-sm 2xl:h-36">
          <Image
            alt={`Game thumbnail for ${game?.title}`}
            src={imageUrl}
            quality={75}
            className="rounded-radius-sm object-cover object-bottom"
            fill
            sizes="(max-width: 768px) 50vw, (max-width: 1200px) 33vw, 15vw"
          />
        </div>
        <Text as="span" size="xs" className="mb-1 truncate font-semibold">
          {game?.title}
        </Text>
        <div className="max-w-max text-xs font-medium text-base-primary [&>span]:max-w-full">
          <AmountDisplay amount={amount} currency={currency} />
        </div>
      </Link>
    );
  },
);

WinItemCard.displayName = 'WinItemCard';

export default WinItemCard;
