import React from 'react';
import './styles/background.css';
import AdaptiveImage from '../AdaptiveImage';
import { BackgroundSources } from '../AdaptiveImage/types';
import { cn } from '@/utils/style';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';

const PageBackground = ({
  sources,
  className,
  shadowStyles,
  imageClassName,
  loader,
  quality,
}: {
  sources?: BackgroundSources;
  loader?: any;
  className?: string;
  imageClassName?: string;
  shadowStyles?: string;
  quality?: number;
}) => {
  return (
    <div
      className={cn([
        'page-bg left-1/2 max-w-[1618px] -translate-x-1/2',
        className && className,
      ])}
    >
      <div className={cn(['bg-lateral-left', shadowStyles])}></div>
      <AdaptiveImage
        // TO DO REMOVE THIS
        loader={DEFAULT_IMAGE_LOADER}
        alt="Page Background"
        style={{ objectFit: 'cover' }}
        fill={true}
        fetchPriority="high"
        quality={quality}
        priority
        loading="eager"
        sources={sources}
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
        className={imageClassName}
      />
      <div className={cn(['bg-transparent-bottom', shadowStyles])}></div>
      <div className={cn(['bg-lateral-right', shadowStyles])}></div>
    </div>
  );
};

export default PageBackground;
