import React from 'react';
import Image from 'next/image';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import AdaptiveImage from '../AdaptiveImage';

const cryptoIcons = [
  {
    src: '/assets/footer-icons/btc.svg',
    alt: 'Bitcoin',
    width: 95,
    height: 20,
  },
  {
    src: '/assets/footer-icons/bch.svg',
    alt: 'Bitcoin Cash',
    width: 114,
    height: 20,
  },
  {
    src: '/assets/footer-icons/eth.svg',
    alt: 'Ethereum',
    width: 112,
    height: 20,
  },
  {
    src: '/assets/footer-icons/ltc.svg',
    alt: 'Litecoin',
    width: 86,
    height: 20,
  },
  {
    src: '/assets/footer-icons/dog.svg',
    alt: 'Dogecoin',
    width: 89,
    height: 20,
  },
  {
    src: '/assets/footer-icons/usdt.svg',
    alt: 'Tether',
    width: 93,
    height: 20,
  },
  { src: '/assets/footer-icons/trx.svg', alt: 'Tron', width: 59, height: 20 },
  {
    src: '/assets/footer-icons/ada.svg',
    alt: 'Cardano',
    width: 99,
    height: 20,
  },
  {
    src: '/assets/footer-icons/bnb.svg',
    alt: 'Binance',
    width: 97,
    height: 20,
  },
  { src: '/assets/footer-icons/xrp.svg', alt: 'Ripple', width: 70, height: 20 },
];

const adaptiveIcons = [
  {
    alt: 'Sol',
    width: 70,
    height: 20,
    sources: {
      desktop: {
        light: '/assets/footer-icons/sol.svg',
        dark: '/assets/footer-icons/sol-dark.svg',
      },
    },
  },
  {
    alt: 'USDC',
    width: 70,
    height: 20,
    sources: {
      desktop: {
        light: '/assets/footer-icons/usdc.svg',
        dark: '/assets/footer-icons/usdc-dark.svg',
      },
    },
  },
];

const CryptoList = () => {
  return (
    <div className="relative flex flex-row flex-wrap justify-center gap-4 border-b border-surface-default p-3 opacity-50 lg:p-7 dark:opacity-100">
      {cryptoIcons.map(({ src, alt, width, height }) => (
        <Image key={alt} src={src} alt={alt} width={width} height={height} />
      ))}
      {adaptiveIcons.map(({ alt, width, height, sources }) => (
        <AdaptiveImage
          key={alt}
          className="dark:opacity-25"
          loader={DEFAULT_IMAGE_LOADER}
          alt={alt}
          fill={false}
          width={width}
          height={height}
          sources={sources}
        />
      ))}
    </div>
  );
};

export default CryptoList;
