'use client';
import { useFiatFromCents } from '@/hooks/fiat';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import CryptoTooltip from '@/components/CryptoTooltip';
import React from 'react';
import FiatAmount from '../FiatAmount';
import CryptoAmount from '../CryptoAmount';
import { cn } from '@/utils/style';

const AmountDisplay: React.FC<{
  amount: number;
  currency: string;
  largeIcon?: boolean;
}> = ({ amount, currency = 'btc', largeIcon = false }) => {
  const fiatAmount = useFiatFromCents(currency, amount);
  const { formatCurrency } = useCurrencyFormatter();

  const formattedCryptoAmount = formatCurrency(amount, currency, {
    style: 'decimal',
  });

  return (
    <CryptoTooltip
      cryptoAmount={
        <FiatAmount removeIcon fiatAmount={fiatAmount} currency={currency} />
      }
      cryptoCode={currency}
      className={cn([
        'flex items-center space-x-1',
        largeIcon &&
          ' [&>div>div>img]:h-7 [&>div>div>img]:w-7 [&>div>div]:h-7 [&>div>div]:w-7',
      ])}
      alwaysShow
    >
      <CryptoAmount
        balance={formattedCryptoAmount as string}
        currency={currency}
      />
    </CryptoTooltip>
  );
};

export default AmountDisplay;
