import React, { Suspense } from 'react';
import { useAuth } from '@/hooks/auth';
// @ts-ignore
import HeroBanner from './banner';
import HeroSlider from './hero-slider';
import { usePathname } from 'next/navigation';

const Hero = () => {
  const pathname = usePathname();
  const { user } = useAuth();

  const isHomepage = pathname === '/';
  const isCasino = pathname === '/casino';
  const isThemes = pathname?.includes('themes');

  if (isCasino || isThemes) {
    return <HeroSlider />;
  }

  if (isHomepage && !user?.isAuthenticated) {
    return <HeroBanner />;
  }

  return <HeroSlider />;
};

export default Hero;
