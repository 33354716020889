import PageBackground from '@/components/PageBackground';

const Picture = () => {
  return (
    <PageBackground
      sources={{
        desktop: {
          dark: '/assets/hero-background/hero-background-desktop.webp',
          light: '/assets/hero-background/hero-background-desktop-light.webp',
        },
        tablet: {
          dark: '/assets/hero-background/hero-background-tablet.webp',
          light: '/assets/hero-background/hero-background-tablet-light.webp',
        },
        mobile: {
          dark: '/assets/hero-background/hero-background-mobile.webp',
          light: '/assets/hero-background/hero-background-mobile-light.webp',
        },
      }}
    />
  );
};

export default Picture;
